import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from 'reactstrap';
import Select from 'react-select';
import defaultImage from '../../assets/img/image_placeholder.jpg';
import { imageTypes } from '../../helpers/nomenclators';

class ImageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      image: {},
      file: null
    };
  }

  componentWillMount() {
    let { image } = this.props;
    this.setState({ image });
  }

  close() {
    this.setState({
      nameValid: null,
      typeValid: null,
      descriptionValid: null
    });
    this.props.closeModal();
  }

  onChange(name, nameValid, value, valid) {
    let { image } = this.state;

    this.setState({
      image: { ...image, [name]: value },
      [nameValid]: valid ? 'has-success' : 'has-danger'
    });
  }

  validate() {
    let name = this.name;
    let description = this.description;

    this.setState({
      [name.attributes.getNamedItem('namevalid').value]: name.validity.valid
        ? 'has-success'
        : 'has-danger',
      [description.attributes.getNamedItem('namevalid').value]: description
        .validity.valid
        ? 'has-success'
        : 'has-danger'
    });

    return name.validity.valid && description.validity.valid;
  }

  handleImageChange(file) {
    let { image } = this.state;
    let reader = new FileReader();

    reader.onloadend = () => {
      image.photo = reader.result;
      this.setState({ image, file: file });
    };
    reader.readAsDataURL(file);
  }

  handleClick() {
    let input = document.createElement('input');

    input.type = 'file';
    input.onchange = e => {
      e.preventDefault();
      this.handleImageChange(e.target.files[0]);
    };
    input.click();
  }

  render() {
    let { image } = this.state,
      { t } = this.props;

    let img = image.photo ? image.photo : defaultImage;

    return (
      <Modal size="md" isOpen={this.props.show} toggle={() => this.close()}>
        <ModalHeader
          className="justify-content-center"
          toggle={() => this.close()}
        >
          {image && image._id ? t('Edit Image') : t('New Image')}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col xs={12} className={'display-flex justify-content-center'}>
              <div
                className="avatar-image"
                style={{
                  backgroundImage: `url('${img}')`
                }}
              />
            </Col>
            <Col xs={12} className={'justify-content-center text-center'}>
              <Button color="info" onClick={() => this.handleClick()}>
                <Trans>Change</Trans>
              </Button>
            </Col>
          </Row>
          {image ? (
            <Form className="form-horizontal">
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' + this.state.nameValid
                    }
                  >
                    <Label>
                      <Trans>Name</Trans>
                    </Label>
                    <Input
                      type="text"
                      innerRef={node => (this.name = node)}
                      namevalid="nameValid"
                      value={image.name || ''}
                      required="required"
                      onChange={event =>
                        this.onChange(
                          'name',
                          'nameValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <FormGroup>
                    <Label>
                      <Trans>Type</Trans>
                    </Label>
                    <Select
                      className="primary first-letter-uppercase"
                      innerRef={node => (this.type = node)}
                      namevalid="typeValid"
                      value={image.type}
                      options={imageTypes.map(p => ({ label: t(p), value: p }))}
                      onChange={event => {
                        this.onChange(
                          'type',
                          'typeValid',
                          event ? event.value : null,
                          !!event
                        );
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs={12} sm={12} md={12} lg={12}>
                  <FormGroup
                    className={
                      'has-label form-validation-40 ' +
                      this.state.descriptionValid
                    }
                  >
                    <Label>
                      <Trans>Description</Trans>
                    </Label>
                    <Input
                      type="textarea"
                      innerRef={node => (this.description = node)}
                      namevalid="descriptionValid"
                      value={image.description || ''}
                      onChange={event =>
                        this.onChange(
                          'description',
                          'descriptionValid',
                          event.target.value,
                          event.target.validity.valid
                        )
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button color="default" onClick={() => this.props.closeModal()}>
            <Trans>Close</Trans>
          </Button>
          <Button
            color="info"
            onClick={() => {
              if (this.validate()) {
                this.setState({
                  nameValid: null,
                  descriptionValid: null
                });
                this.props.saveImage(image, this.state.file);
              }
            }}
          >
            <Trans>Save</Trans>
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default connect()(translate('translations-fr')(ImageModal));
