import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Trans, translate } from 'react-i18next';
import {
  Button,
  Card,
  CardBody,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row
} from 'reactstrap';
import {
  AlertConfirm,
  PaginationCustom,
  PanelHeader,
  Spinner
} from '../../components';
import Select from 'react-select';
import { all, photo, remove, save } from '../../helpers/actions/images';
import { imageTypes } from '../../helpers/nomenclators';
import ImageCard from './ImageCard';
import ImageModal from './ImageModal';

class ImagesList extends Component {
  state = {
    loading: true,
    images: [],
    count: 0,
    page: 1,
    limit: 18,
    search: '',
    type: '',
    image: null,
    imageDelete: null
  };

  componentDidMount() {
    this.load();
  }

  load() {
    this.setState({ loading: true });

    let { page, limit, search, type } = this.state,
      { dispatch } = this.props;
    const offset = page * limit - limit > 0 ? page * limit - limit : 0;

    dispatch(all({ limit, offset, search, type }))
      .then(({ data, meta }) =>
        this.setState({ images: data, ...meta, loading: false })
      )
      .catch(() => this.setState({ loading: false }));
  }

  onSave(imageEdit, photoFile) {
    this.setState({ loading: true, image: null });

    let { dispatch } = this.props;

    dispatch(save(imageEdit))
      .then(imageRes => {
        if (imageRes._id && photoFile) {
          dispatch(photo(imageRes._id, photoFile))
            .then(() => this.load())
            .catch(() => this.load());
        } else this.load();
      })
      .catch(() => this.setState({ loading: false }));
  }

  deleteImage(id) {
    this.setState({ loading: true, imageDelete: null });

    let { dispatch } = this.props;

    dispatch(remove(id))
      .then(() => this.load())
      .catch(() => this.setState({ loading: false }));
  }

  render() {
    let {
        loading,
        page,
        limit,
        count,
        images,
        image,
        type,
        imageDelete
      } = this.state,
      { t } = this.props;

    return (
      <div>
        <PanelHeader size="sm" />
        <div className="content">
          {loading ? <Spinner /> : null}
          <Card>
            <CardBody>
              <Form>
                <Row>
                  <Col className={'text-left'} xs={12} md={4}>
                    <FormGroup>
                      <Label>
                        <Trans>Search</Trans>
                      </Label>
                      <Input
                        type="text"
                        value={this.state.search}
                        onChange={event =>
                          this.setState(
                            {
                              page: 1,
                              search: event.target.value
                            },
                            () => this.load()
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col xs={12} md={3}>
                    <FormGroup>
                      <Label>
                        <Trans>Type</Trans>
                      </Label>
                      <Select
                        className="primary"
                        options={imageTypes.map(p => ({
                          label: t(p),
                          value: p
                        }))}
                        value={type ? type : null}
                        onChange={event => {
                          this.setState(
                            {
                              page: 1,
                              type: event ? event.value : null
                            },
                            () => this.load()
                          );
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col className={'text-right'} xs={12} md={5}>
                    <Button
                      color="info"
                      onClick={() => this.setState({ image: {} })}
                    >
                      <Trans>Add</Trans>
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>

          <div style={{ position: 'relative' }}>
            {images.length > 0 ? (
              <div>
                <Row>
                  {images.map((image, key) => (
                    <Col key={key} xs={12} sm={12} md={4} lg={4}>
                      <ImageCard
                        image={image}
                        onEdit={imageEdit =>
                          this.setState({ image: imageEdit })
                        }
                        onDelete={deleteImage =>
                          this.setState({ imageDelete: deleteImage })
                        }
                      />
                    </Col>
                  ))}
                </Row>
                <Row>
                  <Col xs={12} className={'text-center'}>
                    <PaginationCustom
                      className="display-flex justify-content-center"
                      page={page}
                      count={count}
                      maxPerPage={limit}
                      onPageChange={page =>
                        this.setState({ page }, () => this.load())
                      }
                    />
                  </Col>
                </Row>
              </div>
            ) : (
              <Row>
                <Col xs={12} md={12}>
                  <Card>
                    <CardBody>
                      <p className={'text-not-found text-center'}>
                        <Trans>No images found</Trans>
                      </p>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
          </div>

          {!!image ? (
            <ImageModal
              show={!!image}
              image={image}
              closeModal={() => this.setState({ image: null })}
              saveImage={(imageEdit, photo) => this.onSave(imageEdit, photo)}
            />
          ) : null}

          {!imageDelete ? null : (
            <AlertConfirm
              message={'The image cannot be recovered'}
              onCancel={() => this.setState({ imageDelete: null })}
              onConfirm={() => this.deleteImage(imageDelete._id)}
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect()(translate('translations-fr')(ImagesList));
