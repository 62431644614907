import React from 'react';
// javascript plugin used to create scrollbars on windows
import { Route, Switch } from 'react-router-dom';

import projectRoutes from '../../routes/catalogs.jsx';

class Catalogs extends React.Component {
  render() {
    return (
      <div className="projects_layout">
        <Switch>
          {projectRoutes.map((prop, key) => {
            let { path, ...rest } = prop;
            return (
              <Route
                exact
                path={`${this.props.match.url}${path}`}
                {...rest}
                key={key}
              />
            );
          })}
        </Switch>
      </div>
    );
  }
}

export default Catalogs;
