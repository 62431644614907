import { notify } from './index';

export const all = params => (dispatch, getState, { api }) =>
  api.Images.all(params).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const one = id => (dispatch, getState, { api }) =>
  api.Images.one(id).catch(err => {
    dispatch(notify('danger', err.message));
    throw err;
  });

export const save = image => (dispatch, getState, { api }) =>
  api.Images.save(image)
    .then(response => {
      dispatch(notify('info', 'Image saved'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const remove = id => (dispatch, getState, { api }) =>
  api.Images.del(id)
    .then(response => {
      dispatch(notify('info', 'Image deleted'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });

export const photo = (id, file) => (dispatch, getState, { api }) =>
  api.Images.photo(id, file)
    .then(response => {
      dispatch(notify('info', 'Image saved'));
      return Promise.resolve(response);
    })
    .catch(err => {
      dispatch(notify('danger', err.message));
      throw err;
    });
